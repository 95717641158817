import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactCaptureForm from "../components/contact-capture-form"

class DownloadFile extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Download a PDF about human(Ethos) Services" />
        <h1>Download a PDF about our Services</h1>
        <h4> Behavior Change + Ethical Theory + Data Science</h4>
        <p><strong>Employee retention is <em>hard.</em></strong> We understand
        which key measures drive better retention. And we have a plan +
        framework to help you improve.</p>
        <p><em>To download a 2-page pdf about our services and team, please complete
        the following form. You'll then be provide a download link.</em></p>

        <ContactCaptureForm />

      </Layout>
    )
  }
}

export default DownloadFile

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
