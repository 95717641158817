// Forms suck.
// Used gatsby-plugin-mailchimp.
// The used component from Gatsby website. Old commit with styles from recent. Old below:
// from https://github.com/gatsbyjs/gatsby/blob/6e688a382485ddae4de4b35799e46c71c1f3b9e4/www/src/components/email-capture-form.jsjs

import React from "react"
import styled from "@emotion/styled"
import ReactGA from 'react-ga'

import { MdFileDownload } from "react-icons/md"

import { rhythm, scale } from "../utils/typography"
import { colors, space, breakpoints } from "../utils/presets"
import { formInput } from "../utils/styles"
import { buttonStyles } from "../utils/styles"
import addToMailchimp from "gatsby-plugin-mailchimp"

import humanPdf from "../files/human-ethos-retention.pdf"

const SingleLineInput = styled(`input`)`
  ${formInput};
  margin-bottom: ${rhythm(.75)};
  :focus {
    border-color: ${colors.humans};
    outline: 0;
    box-shadow: 0 0 0 ${rhythm(space[1])} rgba(18,142,151,0.25);
  }
`

const Select = styled(`select`)`
  display: block;
  font-weight: 300;
  margin-bottom: ${rhythm(.75)};
  background-color: ${colors.white};
  border: 1px solid ${colors.humans};
  width: 100%;
  border-radius: 2px;
  color: ${colors.humans};
  padding: ${rhythm(space[1])} ${rhythm(space[2])};
  vertical-align: middle;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  :focus {
    box-shadow: 0 0 0 ${rhythm(space[1])} rgba(18,142,151,0.25);
    outline: 0;
  }
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
  ::-ms-expand {
    display: none;
  }
`

// const CheckBox = styled(`input`)`
//   ${formInput};
//   margin-bottom: ${rhythm(.75)};
//   backgroundColor: ${colors.white};
//   border: 1px solid ${colors.humans};
//   height: 44px;
//   display: inline-block;
//   position: absolute;
//   borderRadius: 2;
//   color: ${colors.humans};
//   padding: 0,
//   font-size: 14px;
// `

const CheckBox = styled(`input`)`
  -ms-transform: scale(1.5); // IE
  -moz-transform: scale(1.5); // FF
  -webkit-transform: scale(1.5); // Safari and Chrome
  -o-transform: scale(1.5); // Opera
  margin: 0 15px;
`

// this one.... grrrrr
const InnerFormDiv = styled(`div`)`
  width: 100%;
  ${breakpoints.md} {
    display: block;
  }
  ${breakpoints.lg} {
    display: inherit;
  }
`

const ErrorMessage = styled(`div`)`
  color: ${colors.warning};
  margin: ${rhythm(space[2])} 0 0;
  display: block;
`

const SuccessMessage = styled(`div`)`
  color: ${colors.darkgreen};
  margin-top: ${rhythm(1)};
`

const Submit = styled(`button`)`
  ${buttonStyles.default};
  font-size: 1.125rem;
  width: auto;
  margin-top: ${rhythm(space[3])};

  span {
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  ${breakpoints.lg} {
    width: auto;
    margin-top: 0;
    margin-left: ${rhythm(space[2])};
  }
`

class ContactCaptureForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: ``,
      FNAME: ``,
      LNAME: ``,
      CO_NAME: ``,
      CO_SIZE: ``,
      PHONE: ``,
      'group[859][1]': false
    }
  }

  // Update state each time user edits their email address
  _handleInputChange = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // need something like checkbox for select

    this.setState({
      [name]: value
    })
  }

  // Post to MC server & handle its response
  _postEmailToMailchimp = (email, attributes) => {
    addToMailchimp(email, attributes)
      .then(result => {
        // Mailchimp always returns a 200 response
        // So we check the result for MC errors & failures
        // hacky validation
        if (this.state.FNAME === '' || this.state.LNAME === '' ||
            this.state.CO_NAME === '' || this.state.CO_SIZE === '') {
              this.setState({
                status: `error`,
                msg: 'Please complete all required fields marked with an " * ".',
            })
        } else if (result.result !== `success`) {
          this.setState({
            status: `error`,
            msg: result.msg,
          })
        } else {
          // Email address succesfully subcribed to Mailchimp
          this.setState({
            status: `success`,
            msg: result.msg,
          })
        }
      })
      .catch(err => {
        // Network failures, timeouts, etc
        this.setState({
          status: `error`,
          msg: err,
        })
      })
  }

  _handleFormSubmit = e => {
    e.preventDefault()
    e.stopPropagation()

    // stupid swith for checkbox not supposed to pass at all wtf
    if (this.state['group[859][1]']) {
      this.setState(
        {
          status: `sending`,
          msg: null,
        },
        this._postEmailToMailchimp(this.state.email, {
          pathname: document.location.pathname,
          FNAME: this.state.FNAME,
          LNAME: this.state.LNAME,
          CO_NAME: this.state.CO_NAME,
          CO_SIZE: this.state.CO_SIZE,
          PHONE: this.state.PHONE,
          'group[859][1]': 1
        })
      )
    } else {
      this.setState(
        {
          status: `sending`,
          msg: null,
        },
        this._postEmailToMailchimp(this.state.email, {
          pathname: document.location.pathname,
          FNAME: this.state.FNAME,
          LNAME: this.state.LNAME,
          CO_NAME: this.state.CO_NAME,
          CO_SIZE: this.state.CO_SIZE,
          PHONE: this.state.PHONE
        })
      )
    }
  }

  buttonEvent() {
    // Detect each click of the financial PDF
    ReactGA.event({
      category: 'DownloadPDF',
      action: 'User submitted form to download pdf'
    })
  }

  linkEvent() {
    // Detect each click of the financial PDF
    ReactGA.event({
      category: 'DownloadPDF',
      action: 'User clicked link post-form to download pdf'
    })
  }

  render() {

    return (
      <div
        style={{
          marginBottom: rhythm(1)
        }}
      >
        {this.state.status === `success` ? (
          <SuccessMessage>Thank you! Here is your download link:<br />
            <a href={humanPdf} onClick={this.linkEvent} target="_blank" rel="noopener noreferrer" id="link-services-pdf">
              <strong>Click Here to Download PDF</strong>
            </a>
          </SuccessMessage>
        ) : (
          <div>
            <p
              style={{
                marginTop: rhythm(1),
                marginBottom: rhythm(.25)
              }}
            >
            </p>
            <form
              id="email-capture"
              method="post"
              noValidate
              style={{
                margin: 0,
              }}
            >
              <InnerFormDiv>
                <SingleLineInput
                  id='mce-FNAME'
                  type="text"
                  name="FNAME"
                  aria-label='FirstName'
                  placeholder="First Name*"
                  value = {this.state.FNAME}
                  onChange={this._handleInputChange}
                />
                <SingleLineInput
                  id='mce-LNAME'
                  type="text"
                  name="LNAME"
                  aria-label='LastName'
                  placeholder="Last Name*"
                  value = {this.state.LNAME}
                  onChange={this._handleInputChange}
                />
                <SingleLineInput
                  id='email'
                  type="email"
                  name="email"
                  aria-label='Email*'
                  placeholder="your.email@example.com*"
                  value = {this.state.email}
                  onChange={this._handleInputChange}
                />
                <SingleLineInput
                  id='mce-CO_NAME'
                  type="text"
                  name="CO_NAME"
                  aria-label='CompanyName'
                  placeholder="Your Company Name*"
                  value = {this.state.CO_NAME}
                  onChange={this._handleInputChange}
                />
                <Select
                  id="mce-CO_SIZE"
                  name="CO_SIZE"
                  value = {this.state.CO_SIZE}
                  onChange={this._handleInputChange}
                >
                  	<option value="">Company or Group Size*</option>
                  	<option value="1-10">1-10</option>
                    <option value="11-50">11-50</option>
                    <option value="51-150">51-150</option>
                    <option value="151-500">151-500</option>
                    <option value="501-1500">501-1500</option>
                    <option value="1501-5000">1501-5000</option>
                    <option value="5000+">5000+</option>
                </Select>
                <SingleLineInput
                  id="mce-PHONE"
                  type="text"
                  name="PHONE"
                  aria-label='Phone'
                  placeholder="Phone Number"
                  value = {this.state.PHONE}
                  onChange={this._handleInputChange}
                  style={{
                    marginBottom: rhythm(.4)
                  }}
                />
                <div
                  style={{
                    marginBottom: rhythm(1)
                  }}
                >
                    <span
                      style={{
                        fontWeight: '500',
                        fontStyle: 'italic',
                        color: colors.humans
                      }}
                    >
                      I would like to schedule a time to talk:
                    </span>
                    <ul
                      style={{
                        padding: 0,
                        margin: 0,
                        listStyle: 'none'
                      }}
                    ><li>
                      <CheckBox
                        type="checkbox"
                        value="1"
                        name="group[859][1]"
                        id="mce-group[859]-859-0"
                        checked = {this.state['group[859][1]']}
                        onChange={this._handleInputChange}
                      />
                      <label
                        style={{
                          fontWeight: '300',
                          ...scale(0),
                          color: colors.humans,
                        }}
                      >
                        Yes please!
                      </label>
                    </li>
                  </ul>
                </div>
                <Submit
                  type="submit"
                  onClick={(event) => { this._handleFormSubmit(event); this.buttonEvent(event);}}
                  id="button-services-pdf"
                >
                  <span>
                    Submit for Download Link
                    <MdFileDownload />
                  </span>
                </Submit>

              </InnerFormDiv>
            </form>
            {this.state.status === `error` && (
              <ErrorMessage
                dangerouslySetInnerHTML={{ __html: this.state.msg }}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default ContactCaptureForm
